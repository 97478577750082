import { sortBy, zip, flatten } from 'lodash'
import api from '../api'

export const START_SEARCHING = 'START_SEARCHING'
export const SEARCH_COMPLETE = 'SEARCH_COMPLETE'

export const search = (algoliaIndices, query) => (dispatch) => {
  dispatch({ type: START_SEARCHING })

  const searchPromiseStructure = getSearchPromiseStructure({
    algoliaIndices,
    query,
  })

  return promiseAllInSearchPromiseStructure(searchPromiseStructure)
    .then((results) => {
      const combinedHits = getCombinedHits(results)

      dispatch({ type: SEARCH_COMPLETE, hits: combinedHits, query })

      api.post('/api/v1/searches', {
        query,
        results: combinedHits,
      })
    })
    .catch((error) => {
      alert('Error searching: ' + error)
    })
}

const searchPromise = (query) => ({ baseUrl, algoliaIndex }) =>
  new Promise((resolve, reject) => {
    algoliaIndex.search(
      { query, getRankingInfo: true },
      (error, { hits } = {}) => {
        if (error) {
          reject(error)
        } else {
          resolve({ baseUrl, hits, index: algoliaIndex.indexName })
        }
      },
    )
  })

const getSearchPromiseStructure = ({ algoliaIndices, query }) => {
  const searchPromises = {}

  const indexKeys = Object.keys(algoliaIndices)
  indexKeys.forEach((indexKey) => {
    searchPromises[indexKey] = algoliaIndices[indexKey].map(
      searchPromise(query),
    )
  })

  return searchPromises
}

const promiseAllInSearchPromiseStructure = (searchPromiseStructure) => {
  const searchResults = {}

  const indexKeys = Object.keys(searchPromiseStructure)
  indexKeys.forEach((indexKey) => {
    searchResults[indexKey] = Promise.all(searchPromiseStructure[indexKey])
  })

  const resultsPromise = Promise.all(Object.values(searchResults)).then(
    (resolvedValues) => {
      const results = {}
      indexKeys.forEach((key, index) => {
        results[key] = resolvedValues[index]
      })
      return results
    },
  )

  return resultsPromise
}

const getCombinedHits = (results) => {
  const combinedHits = {}
  Object.keys(results).map((key) => {
    const sortedResultLists = sortBy(results[key], ['index'])
    const resultsBySite = sortedResultLists.map((result) => {
      return result.hits.map((hit) => ({
        baseUrl: result.baseUrl,
        hit,
      }))
    })

    combinedHits[key] = flatten(zip(...resultsBySite)).filter((item) => !!item)

    if (key == 'events') {
      combinedHits[key] = combinedHits[key].sort((a, b) => {
        const startA = moment(a.hit.starts_at)
        const startB = moment(b.hit.starts_at)

        return startA > startB ? 1 : -1
      })
    }
  })

  return combinedHits
}
