import React, { Component } from 'react'

export default class Wrapper extends Component {
  render() {
    const { hits = [], resultLimit, render } = this.props

    const hitsToDisplay = hits.slice(0, resultLimit)
    const isLimited = hits.length > resultLimit

    return (
      <div className="search-results__result-set">
        {this.renderHeading()}
        {render({ hitsToDisplay, isLimited })}
      </div>
    )
  }

  renderHeading() {
    const { title } = this.props

    return (
      <div className="results-list__heading">
        <h2>{title}</h2>
        {this.renderViewAllLink()}
      </div>
    )
  }

  renderViewAllLink() {
    const { hits = [], resultLimit, onChooseView } = this.props
    if (!onChooseView || hits.length <= resultLimit) {
      return null
    }
    return (
      <button className="t-subtitle-bold" type="button" onClick={onChooseView}>
        View All Results ({hits.length})
      </button>
    )
  }
}
