import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from './store'
import Search from './Search'

export default class App extends Component {
  state = { query: '', lastUpdatedAt: null }

  updateQuery = (query) => {
    this.setState({ query, lastUpdatedAt: Date.now() })
  }

  render = () => (
    <Provider store={store}>
      <Search
        {...this.props}
        externalQuery={this.state.query}
        externalQueryLastUpdatedAt={this.state.lastUpdatedAt}
      />
    </Provider>
  )
}
