import { START_SEARCHING, SEARCH_COMPLETE } from './actions'

const initialData = {
  isSearching: false,
  hits: [],
  sentQuery: undefined,
}

export default (state = initialData, action) => {
  switch (action.type) {
    case START_SEARCHING:
      return {
        ...state,
        isSearching: true,
      }
    case SEARCH_COMPLETE:
      return {
        ...state,
        isSearching: false,
        hits: action.hits,
        sentQuery: action.query,
      }
    default:
      return state
  }
}
