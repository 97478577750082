import React from 'react'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { boldKeyWords } from '../utils.js'

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class Message extends React.PureComponent {
  render() {
    const {
      title,
      slug_with_series,
      banner,
      album_cover,
      communicator,
      description,
    } = this.props.hit

    const url = slug_with_series
      ? `${this.props.baseUrl}/messages/${slug_with_series}`
      : ''
    const image = banner || album_cover

    return (
      <li className="message-results__entry">
        <div
          className="message-results__image"
          style={{ backgroundImage: `url("${image}")` }}
        />
        <div>
          <h2>
            <a href={url} className="primary-link">
              {title}
            </a>
          </h2>
          <div className="t-subtitle-bold">{communicator}</div>
          <div className="message-results__description">
            <ResponsiveEllipsis
              unsafeHTML={boldKeyWords(
                this.props.query,
                removeTags(description || ''),
              )}
              maxLine="3"
              basedOn="letters"
            />
          </div>
        </div>
      </li>
    )
  }
}

const removeTags = (description) => description.replace(/<[^>]*>?/gm, '').trim()

export default Message
