import React from 'react'
import moment from 'moment'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { boldKeyWords } from '../utils.js'

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class Entry extends React.PureComponent {
  render() {
    const entry = this.props.hit
    const { title, author, excerpt, link_url, image_url } = entry

    return (
      <li className="entry-results__entry">
        <div
          className="entry-results__image"
          style={{ backgroundImage: `url("${image_url}")` }}
        />
        <div>
          <h2>
            <a href={link_url} className="primary-link">
              {title}
            </a>
          </h2>
          <div className="entry-results__description">
            <ResponsiveEllipsis
              unsafeHTML={boldKeyWords(this.props.query, excerpt || '')}
              maxLine="3"
              basedOn="letters"
            />
          </div>
        </div>
      </li>
    )
  }
}

export default Entry
