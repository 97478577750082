import React from 'react'

const ResultWrapper = ({ title, hits = [], children, onBack }) => (
  <div>
    <h1 className="results-list__heading">
      {title} ({hits.length})
    </h1>
    <div className="separate-results">
      <div className="separate-results__column">
        {children}
        {renderBackLink({ onBack })}
      </div>
    </div>
  </div>
)

const renderBackLink = ({ onBack }) => (
  <div>
    <button type="button" className="bg-primary__btn lg-btn" onClick={onBack}>
      Back to All Results
    </button>
  </div>
)

export default ResultWrapper
