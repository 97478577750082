import React from 'react'
import Wrapper from './Wrapper'
import Event from '../Item/Event'

const EVENT_LIMIT = 3

const EventResults = ({ hits, client, onChooseView, query }) => (
  <Wrapper
    client={client}
    hits={hits}
    resultLimit={EVENT_LIMIT}
    onChooseView={onChooseView}
    title="Events"
    render={({ hitsToDisplay }) => (
      <ul className="event-results">
        {hitsToDisplay.map((props) => (
          <Event
            key={props.baseUrl + props.hit.objectID}
            {...props}
            query={query}
          />
        ))}
      </ul>
    )}
  />
)

export default EventResults
