import React from 'react'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { boldKeyWords } from '../utils.js'

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class Page extends React.PureComponent {
  render() {
    const { title, slug, description } = this.props.hit

    const url = `${this.props.baseUrl}/${slug || ''}`

    return (
      <li>
        <h2 className="page-results__title">
          <a href={url} className="primary-link">
            {title}
          </a>
        </h2>
        <div className="page-results__description">
          <ResponsiveEllipsis
            unsafeHTML={boldKeyWords(this.props.query, description || '')}
            maxLine="2"
            basedOn="letters"
          />
        </div>
      </li>
    )
  }
}

export default Page
