import React from 'react'
import ReactDOM from 'react-dom'
import App from '../javascript/search/App'

window.searchComponents = []

const searchContainers = document.querySelectorAll('.search-container')

Array.from(searchContainers).forEach((searchContainer) => {
  const { appId, apiKey } = searchContainer.dataset
  let {
    quickLinks,
    seriesSiteReferralMessage,
    indicesPages,
    indicesEvents,
    indicesMessages,
    indicesEntries,
    indicesFeeds
  } = searchContainer.dataset

  const indices = {
    pages: JSON.parse(indicesPages),
    events: JSON.parse(indicesEvents),
    messages: JSON.parse(indicesMessages),
    entries: JSON.parse(indicesEntries),
    feeds: JSON.parse(indicesFeeds)
  }

  try {
    quickLinks = JSON.parse(quickLinks)
  } catch (e) {
    quickLinks = []
  }

  document.addEventListener('DOMContentLoaded', () => {
    window.searchComponents.push(
      ReactDOM.render(
        <App
          appId={appId}
          apiKey={apiKey}
          quickLinks={quickLinks}
          indices={indices}
          seriesSiteReferralMessage={seriesSiteReferralMessage}
        />,
        searchContainer,
      ),
    )
  })
})
