import React from 'react'
import PageResults from './PageResults'
import EventResults from './EventResults'
import MessageResults from './MessageResults'
import EntryResults from './EntryResults'
import { VIEW } from '../Search'

function postHits(entries, feeds) {
  if (entries && feeds) { return entries.concat(feeds) }
  if (entries) { return entries }
  if (feeds) { return feeds }

  return [];
}

const CombinedResults = ({
  client,
  hits = {},
  seriesSiteReferralMessage,
  onChooseView,
  query,
}) => (
  <div className="combined-results">
    <div className="combined-results__column">
      <PageResults
        client={client}
        hits={hits.pages || []}
        onChooseView={() => onChooseView(VIEW.PAGES)}
        query={query}
      />
    </div>
    <div className="combined-results__column">
      <MessageResults
        client={client}
        hits={hits.messages || []}
        seriesSiteReferralMessage={seriesSiteReferralMessage}
        onChooseView={() => onChooseView(VIEW.MESSAGES)}
        query={query}
      />
      <EventResults
        client={client}
        hits={hits.events || []}
        onChooseView={() => onChooseView(VIEW.EVENTS)}
        query={query}
      />
      <EntryResults
        client={client}
        hits={postHits(hits.entries, hits.feeds) || []}
        onChooseView={() => onChooseView(VIEW.ENTRIES)}
        query={query}
      />
    </div>
  </div>
)

export default CombinedResults
