import React from 'react'
import Wrapper from './Wrapper'
import Message from '../Item/Message'
import { filterDuplicateMessages } from '../utils.js'

const MESSAGE_LIMIT = 3

const MessageResults = ({
  hits,
  client,
  seriesSiteReferralMessage,
  onChooseView,
  query,
}) => (
  <Wrapper
    client={client}
    hits={filterDuplicateMessages(hits)}
    resultLimit={MESSAGE_LIMIT}
    onChooseView={onChooseView}
    title="Messages"
    render={({ hitsToDisplay, isLimited }) => (
      <div>
        <ul className="message-results">
          {hitsToDisplay.map((props) => (
            <Message
              key={props.baseUrl + props.hit.objectID}
              {...props}
              query={query}
            />
          ))}
        </ul>
        {!isLimited && (
          <div
            className="message-results__series-site-referral-message wysiwyg-content"
            dangerouslySetInnerHTML={{ __html: seriesSiteReferralMessage }}
          />
        )}
      </div>
    )}
  />
)

export default MessageResults
