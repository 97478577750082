import React from 'react'
import ResultWrapper from './ResultWrapper'
import Page from './Item/Page'

const PageResults = ({ hits, client, onBack, query }) => (
  <ResultWrapper client={client} hits={hits} onBack={onBack} title="Pages">
    <ul className="page-results">
      {hits.map((props) => (
        <Page
          key={props.baseUrl + props.hit.objectID}
          {...props}
          query={query}
        />
      ))}
    </ul>
  </ResultWrapper>
)

export default PageResults
