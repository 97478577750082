import React from 'react'
import ResultWrapper from './ResultWrapper'

const EmptyResults = ({ links }) => (
  <div className="quick-links">
    {links.map((link, index) => (
      <a key={index} href={link.relative_url} className="primary-link">
        {link.display_name}
      </a>
    ))}
  </div>
)

export default EmptyResults
