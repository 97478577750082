import React from 'react'
import Wrapper from './Wrapper'
import Page from '../Item/Page'

const PAGE_LIMIT = 10

const PageResults = ({ hits, client, onChooseView, query }) => (
  <Wrapper
    client={client}
    hits={hits}
    resultLimit={PAGE_LIMIT}
    onChooseView={onChooseView}
    title="Pages"
    render={({ hitsToDisplay }) => (
      <ul className="page-results">
        {hitsToDisplay.map((props) => {
          return (
            <Page
              key={props.baseUrl + props.hit.objectID}
              {...props}
              query={query}
            />
          )
        })}
      </ul>
    )}
  />
)

export default PageResults
