import React from 'react'
import Wrapper from './Wrapper'
import Entry from '../Item/Entry'

const ENTRY_LIMIT = 3

const EntryResults = ({ hits, client, onChooseView, query }) => (
  <Wrapper
    client={client}
    hits={hits}
    resultLimit={ENTRY_LIMIT}
    onChooseView={onChooseView}
    title="Posts"
    render={({ hitsToDisplay }) => (
      <ul className="entry-results">
        {hitsToDisplay.map((props) => (
          <Entry
            key={props.baseUrl + props.hit.objectID}
            {...props}
            query={query}
          />
        ))}
      </ul>
    )}
  />
)

export default EntryResults
