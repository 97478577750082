import React, { Component } from 'react'
import { connect } from 'react-redux'
import algoliasearch from 'algoliasearch'
import CombinedResults from './CombinedResults'
import PageResults from './PageResults'
import EventResults from './EventResults'
import MessageResults from './MessageResults'
import EntryResults from './EntryResults'
import EmptyResults from './EmptyResults'
import api from './api'
import { search } from './store/actions'

export const VIEW = {
  COMBINED: 'COMBINED',
  PAGES: 'PAGES',
  EVENTS: 'EVENTS',
  MESSAGES: 'MESSAGES',
  ENTRIES: 'ENTRIES',
  FEEDS: 'FEEDS'
}

const getInitialState = () => ({
  query: '',
  view: null,
})

function postHits(entries, feeds) {
  if (entries && feeds) { return entries.concat(feeds) }
  if (entries) { return entries }
  if (feeds) { return feeds }

  return [];
}

class Search extends Component {
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.externalQueryLastUpdatedAt !== this.props.externalQueryLastUpdatedAt) {
      this.updateQueryAndSearch(nextProps.externalQuery)
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.isSearching && !this.props.isSearching) {
      this.setState({ view: VIEW.COMBINED })
    }
  }

  state = getInitialState()

  client = algoliasearch(this.props.appId, this.props.apiKey)

  expandIndex = (group) => group.map((index) => ({
    baseUrl: index.base_url,
    algoliaIndex: this.client.initIndex(index.id),
  }))

  algoliaIndices = {
    pages: this.expandIndex(this.props.indices.pages),
    events: this.expandIndex(this.props.indices.events),
    messages: this.expandIndex(this.props.indices.messages),
    entries: this.expandIndex(this.props.indices.entries),
    feeds: this.expandIndex(this.props.indices.feeds)
  }

  handleUpdateQuery = (event) => this.setState({ query: event.target.value })

  handleSubmit = (event) => {
    event.preventDefault()
    this.search()
  }

  search = () => {
    const { query } = this.state

    if (query.trim() === '') {
      this.setState(getInitialState())
      return
    }

    this.props.search(this.algoliaIndices, query)
  }

  updateQueryAndSearch = (query) => {
    this.setState({ query }, this.search)
  }

  setView = (view) => this.setState({ view })

  render() {
    const { query } = this.state

    return (
      <div>
        {this.renderTitle()}
        <div className="search-results">
          <form onSubmit={this.handleSubmit}>
            <div className="query-field">
              <i className="fas fa-search query-field__icon" />
              <input
                type="text"
                role="search"
                className="query-field__input js-search-input"
                placeholder="Type Something"
                value={query}
                onChange={this.handleUpdateQuery}
              />
            </div>
            {this.renderView()}
          </form>
        </div>
      </div>
    )
  }

  renderTitle() {
    return (
      <h1 className="search__title uppercase">
        {this.state.view ? 'Search Results' : 'Search'}
      </h1>
    )
  }

  renderView() {
    const hits = this.props.hits
    const { seriesSiteReferralMessage } = this.props
    const { client } = this

    const goBack = () => this.setView(VIEW.COMBINED)

    switch (this.state.view) {
      case VIEW.PAGES:
        return (
          <PageResults
            client={client}
            hits={hits.pages}
            onBack={goBack}
            query={this.props.sentQuery}
          />
        )
      case VIEW.EVENTS:
        return (
          <EventResults
            client={client}
            hits={hits.events}
            onBack={goBack}
            query={this.props.sentQuery}
          />
        )
      case VIEW.MESSAGES:
        return (
          <MessageResults
            client={client}
            hits={hits.messages}
            seriesSiteReferralMessage={seriesSiteReferralMessage}
            onBack={goBack}
            query={this.props.sentQuery}
          />
        )
      case VIEW.ENTRIES:
        return (
          <EntryResults
            client={client}
            hits={postHits(hits.entries, hits.feeds) || []}
            onBack={goBack}
            query={this.props.sentQuery}
          />
        )
      case VIEW.COMBINED:
        return (
          <CombinedResults
            client={client}
            hits={hits}
            seriesSiteReferralMessage={seriesSiteReferralMessage}
            onChooseView={this.setView}
            query={this.props.sentQuery}
          />
        )
      default:
        return <EmptyResults links={this.props.quickLinks} />
    }
  }
}

export default connect(
  (state) => {
    return {
      isSearching: state.isSearching,
      hits: state.hits,
      sentQuery: state.sentQuery,
    }
  },
  {
    search,
  },
)(Search)
