import React from 'react'
import ResultWrapper from './ResultWrapper'
import Message from './Item/Message'
import { filterDuplicateMessages } from './utils.js'

const MessageResults = ({
  hits,
  client,
  seriesSiteReferralMessage,
  onBack,
  query,
}) => {
  const noDuplicates = filterDuplicateMessages(hits)
  return (
    <ResultWrapper
      client={client}
      hits={noDuplicates}
      onBack={onBack}
      title="Messages"
    >
      <ul className="message-results">
        {noDuplicates.map((props) => (
          <Message
            key={props.baseUrl + props.hit.objectID}
            {...props}
            query={query}
          />
        ))}
      </ul>
      <div
        className="message-results__series-site-referral-message wysiwyg-content"
        dangerouslySetInnerHTML={{ __html: seriesSiteReferralMessage }}
      />
    </ResultWrapper>
  )
}

export default MessageResults
