import React from 'react'
import moment from 'moment'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { boldKeyWords } from '../utils.js'

const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)

class Event extends React.PureComponent {
  render() {
    const {
      title,
      featured_image,
      location_name,
      description,
      pretty_display_date,
      off_site,
      link_url,
    } = this.props.hit

    const target = off_site ? { target: '_blank' } : {}

    return (
      <li className="event-results__entry">
        <div
          className="event-results__image"
          style={{ backgroundImage: `url("${featured_image}")` }}
        />
        <div>
          <h2>
            <a href={link_url} {...target} className="primary-link">
              {title}
            </a>
          </h2>
          <div className="t-subtitle-bold">{pretty_display_date}</div>
          <div className="t-subtitle-bold">{location_name}</div>
          <div className="event-results__description">
            <ResponsiveEllipsis
              unsafeHTML={boldKeyWords(this.props.query, description || '')}
              maxLine="3"
              basedOn="letters"
            />
          </div>
        </div>
      </li>
    )
  }
}

export default Event
