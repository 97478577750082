import React from 'react'
import ResultWrapper from './ResultWrapper'
import Event from './Item/Event'

const EventResults = ({ hits, client, onBack, query }) => (
  <ResultWrapper client={client} hits={hits} onBack={onBack} title="Events">
    <ul className="event-results">
      {hits.map((props) => (
        <Event
          key={props.baseUrl + props.hit.objectID}
          {...props}
          query={query}
        />
      ))}
    </ul>
  </ResultWrapper>
)

export default EventResults
