import React from 'react'
import ResultWrapper from './ResultWrapper'
import Entry from './Item/Entry'

const EntryResults = ({ hits, client, onBack, query }) => (
  <ResultWrapper client={client} hits={hits} onBack={onBack} title="Posts">
    <ul className="event-results">
      {hits.map((props) => (
        <Entry
          key={props.baseUrl + props.hit.objectID}
          {...props}
          query={query}
        />
      ))}
    </ul>
  </ResultWrapper>
)

export default EntryResults
